:root {
  --primaryColor: #85B40D;
  --secondaryColor: #2D343F;
  --tertiaryColor: #ababb2;

  --bgColor: #E2E5EA;
  --bgColorGrey: #F9F9F9;
  --bgColorShade: #E3E4E5;
  --bgColorShaded: #DDDFE2;
  --bgColorShaded2: #96999F;
  --bgColorShaded3: #E1E1E2;
  --bgColorWhite: #fff;
  --gbColorSelect: #EAEAEB;

  --buttonGreen: var(--primaryColor);
  --buttonGreenHover: #698a2b;
  --buttonGreenActive: #76a01f;
  --buttonColdBlack: var(--secondaryColor);
  --buttonColdBlackHover: #81858c;
  --buttonColdBlackActive: #575d65;
  --buttonColdBlack20: #d5d6d9;
  --buttonColdBlack20Hover: var(--gbColorSelect);
  --buttonColdBlack20Active: #c0c2c5;
  --buttonGhost: var(--secondaryColor);
  --buttonGhostHover: var(--buttonColdBlack20Active);
  --buttonGhostActive: var(--buttonColdBlack20Active);

  --linkColor: #168CAC;
  --linkColorBright: #07AAD5;
  --warnColor: #CC2A00;
  --disabledColor: #C0C2C5;
  --hintColor: #f1bb39;

  --marketsColor: #168CAC;
  --newsColor: #F2BB3A;
  --portfolioColor: #85B40D;
  --toolsColor: #E1714D;

  --borderColor: #EFF1F3;

  --textColorBright: #fff;
  --textColorRegular: #333;
  --textColorShade: #919191;
  --textColorMedium: #3D434D;

  --tableBorderColor: #D8D8D8;
  --tableHighlightColor: #E7F3F6;

  --pushUp: #55A51C;
  --pushUpBright: #66D815;
  --pushNeutral: var(--secondaryColor);
  --pushDown: #CC2A00;
  --pushDownBright: #F03505;

  --chartPositiveColor: var(--pushUp);
  --chartNegativeColor: var(--pushDown);
  --chartLineColor: var(--textColorBright);
  --chartGridColor: var(--bgColorGrey);

  --chartLabelColor: #96999F;
  --chartTrendUp: #55A51C;
  --chartTrendNeutral: #55A51C;
  --chartTrendDown: #CC2A00;
  --chartTargetLine: #919191;
  --chartTextColor: #333;
  --chartBarColor: rgb(225, 237, 242);

  --chartColor01: #DFA011;
  --chartColor02: #148CAC;
  --chartColor03: #0B5467;
  --chartColor04: #55A51C;
  --chartColor05: #CC2A00;
  --chartColor06: #BDB822;
  --chartColor07: #23AAAA;
  --chartColor08: #DD8012;
  --chartColor09: #9059BA;
  --chartColor10: #C6126D;
  --chartColor11: #E9BD58;
  --chartColor12: #5BAFC5;
  --chartColor13: #548795;
  --chartColor14: #88C060;
  --chartColor15: #DC6A4C;
  --chartColor16: #D1CE64;
  --chartColor17: #65C4C4;
  --chartColor18: #E8A659;
  --chartColor19: #B28BCF;
  --chartColor20: #D75999;
  --chartColor21: #F6E3B8;
  --chartColor22: #B9DDE6;
  --chartColor23: #B6CCD2;
  --chartColor24: #CCE4BB;
  --chartColor25: #F0BFB2;
}
