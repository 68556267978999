@use '@angular/material'as mat;
@import './mediaqueries.scss';
@import './buttons.scss';

$mat-ivestor: (50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: var(--chartColor02),
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (50: var(--textColorRegular),
    100: var(--textColorRegular),
    200: var(--textColorRegular),
    300: var(--textColorRegular),
    400: var(--textColorRegular),
    500: var(--textColorRegular),
    600: var(--secondaryColor),
    700: var(--secondaryColor),
    800: var(--secondaryColor),
    900: var(--secondaryColor),
    A100: var(--textColorRegular),
    A200: var(--textColorRegular),
    A400: var(--textColorRegular),
    A700: var(--textColorRegular),
  ));

$mat-ivestor-warn: (50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: var(--warnColor),
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (50: var(--textColorRegular),
    100: var(--textColorRegular),
    200: var(--textColorRegular),
    300: var(--textColorRegular),
    400: var(--textColorRegular),
    500: var(--secondaryColor),
    600: var(--secondaryColor),
    700: var(--secondaryColor),
    800: var(--secondaryColor),
    900: var(--secondaryColor),
    A100: var(--textColorRegular),
    A200: var(--secondaryColor),
    A400: var(--secondaryColor),
    A700: var(--secondaryColor),
  ));

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ivestor-primary: mat.define-palette($mat-ivestor);
$ivestor-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ivestor-warn: mat.define-palette($mat-ivestor-warn);

// Create the theme object (a Sass map containing all of the palettes).
$ivestor-theme: mat.define-light-theme($ivestor-primary, $ivestor-accent, $ivestor-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ivestor-theme);

mat-form-field {
  margin: 0;
  width: 100%;

  &.small {
    width: 120px;
  }

  .mat-form-field-suffix {
    position: absolute;
    right: 0;
    top: 14px;
  }

  &:hover {
    .mat-form-field-empty {
      color: var(--secondaryColor);
    }
  }

  .mat-form-field-label {
    color: var(--secondaryColor);

    &.mat-form-field-empty {
      top: 20px;
    }
  }

  .mat-form-field-empty {
    font-size: var(--fontSizeMedium);
    color: var(--bgColorShaded2);
  }

  &.mat-form-field-appearance-fill {

    .mat-form-field-flex {
      background-color: transparent;
      padding-left: 4px;
      padding-right: 1px;
    }

    .mat-form-field-infix {
      padding: 4px 0 9px 0;
    }

    .mat-form-field-underline {
      &::before {
        background-color: var(--secondaryColor);
      }
    }

    .mat-select-arrow-wrapper {
      transform: translateY(-1px);

      .mat-select-arrow {
        color: var(--secondaryColor);
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 8px solid;
      }
    }

    &.mat-form-field-disabled {

      .mat-form-field-label,
      .mat-select-arrow {
        color: var(--disabledColor);
      }

      .mat-form-field-flex {
        background-color: #fff;
      }

      .mat-form-field-underline {
        &::before {
          background-color: var(--disabledColor);
        }
      }
    }
  }

  input.mat-input-element {
    font-size: 16px;
  }

}

.mat-calendar {
  .mat-calendar-body-today {
    border-color: var(--secondaryColor) !important;
  }

  .mat-calendar-body-cell-content {
    color: var(--secondaryColor);
  }

  .mat-calendar-body-selected {
    color: #fff;
  }

  .mat-calendar-table-header,
  .mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color: var(--buttonColdBlack20Active);
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: var(--gbColorSelect);
  }

  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    color: var(--secondaryColor);

    &::after {
      border: none !important;
      font-family: var(--iconFont) !important;
      transform: none !important;
      font-size: 7px;
    }
  }

  .mat-calendar-previous-button {
    &::after {
      content: '\e93a' !important;
      top: -16px !important;
      left: -2px !important;
      right: auto !important;
      bottom: auto !important;
    }
  }

  .mat-calendar-next-button {
    &::after {
      content: '\e93b' !important;
      top: -16px !important;
      left: auto !important;
      right: -2px !important;
      bottom: auto !important;
    }
  }
}

.mat-datepicker-toggle {
  .mat-icon-button {
    width: 25px !important;
    outline: none !important;
  }

  .mat-button-focus-overlay {
    background-color: transparent !important;
    outline: none !important;
  }

  .icon-calendar {
    position: relative;
    top: -3px;
    font-size: 15px;
  }
}

.mat-datepicker-toggle-active {
  .icon-calendar {
    &:before {
      color: var(--chartColor02);
    }
  }
}

.mat-select-panel {

  .mat-option {
    border-bottom: 1px solid var(--tableBorderColor);

    &:hover:not(.mat-option-disabled) {
      background: var(--gbColorSelect);
    }

    &.mat-option.mat-selected:not(.mat-option-multiple) {
      background: map-get($mat-ivestor, 500);
      color: #fff;
    }
  }
}

.ngx-timepicker {
  display: inline-flex !important;
  border-color: var(--secondaryColor) !important;
  margin: 0 var(--spacesM) 0 0;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid var(--secondaryColor) !important;
  width: 100%;

  &:hover {
    &:after {
      background: var(--secondaryColor);
      content: '';
      position: absolute;
      height: 2px;
      width:calc(100% - #{var(--spacesM)});
      bottom: 0px;
    }
  }
}

.ngx-timepicker-control {
  height: 32px !important;
  top: -1px;

  &--active {
    &:after {
      background: var(--chartColor02) !important;
      height: 2px !important;
      bottom: 0px;
    }
  }

  &__input {
    font-size: 16px !important;
  }

  &__arrows {
    top: 0 !important;
  }

  &__arrow {
    position: relative;
    right: 4px;
    color: transparent !important;

    &:hover {
      &:before {
        color: var(--chartColor02);
      }
    }

    &:before {
      color: var(--secondaryColor);
      font-size: 6px;
      position: absolute;
      top: 0;
      left: 0;
      font-family: var(--iconFont);
      content: '\e93b';
    }

    &:last-child {
      &:before {
        content: '\e93a';
        top: -7px;
      }
    }
  }
}

.mat-bottom-sheet-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-width: 280px !important;
  max-width: calc(100vw - 20px) !important;
  padding: 0 !important;
  width: 100vw;

  header {
    color: var(--secondaryColor);
    font-family: var(--mainFont);
    font-size: var(--fontSizeRegular);
    margin: 0 var(--spacesS);
    padding: 0 0 var(--spacesM) 0;
    border-bottom: 1px solid var(--borderColor);

    .close-handler {
      height: 4px;
      width: 24px;
      border-radius: 2px;
      background-color: #E6E6E6;
      position: relative;
      left: calc(50% - 12px);
    }

    .title {
      height: 26px;
      color: var(--primaryColor);
      font-family: var(--mainFontBold);
      font-size: var(--fontSizeHeader5);
      line-height: 26px;
      margin: 7px 0 0 0;
    }
  }

  .content {
    padding: 0 var(--spacesS) var(--spacesM) var(--spacesS);

    .list {
      li {
        color: var(--secondaryColor);
        font-size: var(--fontSizeMedium);
        line-height: 24px;
        padding: 8px 0;
        border-bottom: 1px solid var(--borderColor);
      }
    }

    .close {
      margin: 25px 0 0 0;
      width: 100%;
    }
  }

}

// @include mediaQueryMedium {
//   mat-form-field {
//     margin: 0 var(--spacesM) 0 0;
//     width: 210px;
//   }

//   .mat-bottom-sheet-container {
//     max-width: 375px !important;
//   }
// }


.mat-menu-panel {

  &.flyout {
    min-height: 52px !important;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    padding: 8px;
    margin-right: -10px;
    margin-top: -10px;

    .mat-menu-content {
      display: flex;
      background: #fff;
      padding: var(--spacesS) !important;
      position: relative;
      margin-top: 10px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);

      &:before {
        content: "";
        position: absolute;
        top: -7px;
        right: 11px;
        background: #fff;
        height: 15px;
        width: 15px;
        transform: rotate(45deg);
      }
    }

    &.list {
      margin-left: -8px;
      margin-top: -16px;

      .mat-menu-content {
        margin-top: 0px;

        &:before {
          display: none;
        }

        ul {
          min-width: 200px;

          li {
            border-bottom: 1px solid var(--borderColor);
            color: var(--secondaryColor);
            text-align: left;
            padding: 10px 2px 9px 2px;
            font-family: var(--mainFont);
            font-size: var(--fontSizeRegular);
            line-height: 20px;
            display: flex;
            position: relative;
            white-space: nowrap;

            &:last-child {
              border: none;
            }

            &.active {
              font-family: var(--mainFontBold);
            }

            .label {
              position: relative;
              flex-grow: 1;
            }

            .value {
              text-align: right;
              margin: 0 0 0 10px;
              position: relative;
            }

            &:hover {
              background: var(--borderColor);
              cursor: pointer;
            }
          }
        }
      }
    }

    &.functions-list {
      .mat-menu-content {
        display: flex;
        flex-wrap: wrap;
        font-size: var(--fontSizeMedium);

        button {
          font-size: var(--fontSizeMedium);
          border-bottom: 1px solid var(--borderColor);
          line-height: 42px;
          text-align: left;

          &:last-child {
            border: none;
          }

          span {
            margin-right: 5px;
          }

          &::before {
            font-size: 18px;
          }
        }
      }
    }
  }

  &.flyout-list {
    min-height: 52px !important;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    padding: 8px;
    margin-left: -8px;
    margin-top: -16px;


    .mat-menu-content {
      background: #fff;
      padding: var(--spacesS) !important;
      position: relative;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
    }
  }
}

.mat-slide-toggle {
  user-select: none;

  .mat-slide-toggle-content {
    font-family: var(--mainFontBold);
    color: var(--secondaryColor);
    font-size: 14px;
  }

  .mat-slide-toggle-bar {
    height: 20px !important;
    border-radius: 13px !important;
    background-color: var(--buttonColdBlack20Active);
  }

  .mat-slide-toggle-thumb-container {
    height: 16px !important;
    width: 16px !important;
    top: 2px !important;
    left: 3px !important;
  }

  .mat-slide-toggle-thumb {
    background-color: #fff;
    height: 16px;
    width: 16px;
  }

  &.mat-checked {

    @for $i from 0 to length($ct-series-names) {
      &.color-#{$i + 1} {
        .mat-slide-toggle-bar {
          background-color: nth($ct-series-colors, $i + 1) !important;
        }
      }
    }

    .mat-slide-toggle-thumb-container {
      transform: translate3d(14px, 0, 0) !important;
    }

    .mat-slide-toggle-thumb {
      background-color: #fff;
      height: 16px;
      width: 16px;
    }

    .mat-slide-toggle-bar {
      background-color: var(--primaryColor);
    }
  }
}

@include mediaQueryMedium {
  .mat-bottom-sheet-container {
    max-width: 375px !important;
  }

  mat-form-field {
    margin: 0 var(--spacesM) 0 0;
    width: 210px;
  }

  .ngx-timepicker-control {
    &__arrows {
      top: 5px !important;
    }

    &__arrow {

      &:last-child {
        &:before {
          top: 0;
        }
      }
    }
  }
}
