:root {
  --maxContentSize: 1270px;

  --marginRegular: 28px;

  --spacesXS: 8px;
  --spacesS: 16px;
  --spacesM: 24px;
  --spacesL: 36px;
  --spacesXL: 54px;
}