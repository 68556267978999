app-error {
  display: block;
  width:100%;
}

.error {
  background: none;
  color: var(--textColorShade);
  font-size: var(--fontSizeRegular);
  text-align: center;
}

@mixin chartError {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-image: url("../assets/img/chart.svg");
  background-repeat: repeat-x;
  background-size: auto 100%;
  background-position: center;

  .message {
    align-self: center;
    display: inline-block;
    font-size: var(--fontSizeSmall);
    font-family: var(--mainFontBold);
    position: relative;
    padding: 0 0 0 20px;
    line-height: 16px;

    &:before {
      content: '\e94e';
      font-family: var(--iconFont);
      color: var(--hintColor);
      margin: 0 5px 0 0;
      font-size: 15px;
      position: absolute;
      left: 0px;
      top: -2px;
    }
  }
}
