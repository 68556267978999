@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lc3u8s');
  src:  url('fonts/icomoon.eot?lc3u8s#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lc3u8s') format('truetype'),
    url('fonts/icomoon.woff?lc3u8s') format('woff'),
    url('fonts/icomoon.svg?lc3u8s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-colunm:before {
  content: "\e966";
}
.icon-bell:before {
  content: "\e967";
}
.icon-buy:before {
  content: "\e968";
}
.icon-contacts:before {
  content: "\e969";
}
.icon-dashboard:before {
  content: "\e96a";
}
.icon-edit_inverted:before {
  content: "\e96b";
}
.icon-edit:before {
  content: "\e96c";
}
.icon-email-inverted:before {
  content: "\e96d";
}
.icon-favorits-active .path1:before {
  content: "\e96e";
  color: rgb(61, 67, 77);
}
.icon-favorits-active .path2:before {
  content: "\e96f";
  margin-left: -0.875em;
  color: rgb(230, 0, 0);
}
.icon-favorits:before {
  content: "\e970";
}
.icon-logout .path1:before {
  content: "\e971";
  color: rgb(61, 67, 77);
}
.icon-logout .path2:before {
  content: "\e972";
  margin-left: -1.2177734375em;
  color: rgb(61, 67, 77);
}
.icon-logout .path3:before {
  content: "\e973";
  margin-left: -1.2177734375em;
  color: rgb(230, 0, 0);
}
.icon-my_searches:before {
  content: "\e974";
}
.icon-news-active .path1:before {
  content: "\e975";
  color: rgb(230, 0, 0);
}
.icon-news-active .path2:before {
  content: "\e976";
  margin-left: -1.017578125em;
  color: rgb(45, 52, 63);
}
.icon-news-active .path3:before {
  content: "\e906";
  margin-left: -1.017578125em;
  color: rgb(45, 52, 63);
}
.icon-performance:before {
  content: "\e977";
}
.icon-print:before {
  content: "\e978";
}
.icon-sell:before {
  content: "\e979";
}
.icon-settings:before {
  content: "\e97a";
}
.icon-snowman:before {
  content: "\e97b";
}
.icon-trash:before {
  content: "\e97c";
}
.icon-watchlist:before {
  content: "\e97d";
}
.icon-email:before {
  content: "\e94c";
}
.icon-fax:before {
  content: "\e94d";
}
.icon-phone:before {
  content: "\e952";
}
.icon-Pin:before {
  content: "\e953";
}
.icon-world:before {
  content: "\e954";
}
.icon-check:before {
  content: "\e94a";
  color: #85b40d;
}
.icon-list-view:before {
  content: "\e948";
}
.icon-tile-view:before {
  content: "\e949";
}
.icon-arrow-dropdown-down:before {
  content: "\e93a";
}
.icon-arrow-dropdown-up:before {
  content: "\e93b";
}
.icon-calendar:before {
  content: "\e93c";
  color: #424852;
}
.icon-download:before {
  content: "\e93d";
  color: #424852;
}
.icon-error1 .path1:before {
  content: "\e93e";
  color: rgb(205, 42, 0);
}
.icon-error1 .path2:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-error1 .path3:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-filter:before {
  content: "\e940";
  color: #424852;
}
.icon-info1 .path1:before {
  content: "\e941";
  color: rgb(20, 140, 172);
}
.icon-info1 .path2:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-info1 .path3:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-reset:before {
  content: "\e944";
  color: #424852;
}
.icon-warning1 .path1:before {
  content: "\e945";
  color: rgb(241, 187, 58);
}
.icon-warning1 .path2:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-warning1 .path3:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-share:before {
  content: "\e934";
}
.icon-focus:before {
  content: "\e935";
}
.icon-arrow-big-down:before {
  content: "\e936";
  color: #f5ccc0;
}
.icon-arrow-big-neutral:before {
  content: "\e937";
  color: #dcdedf;
}
.icon-arrow-big-up:before {
  content: "\e938";
  color: #e7f0ce;
}
.icon-copy:before {
  content: "\e933";
}
.icon-burger:before {
  content: "\e931";
}
.icon-close:before {
  content: "\e932";
}
.icon-arrow-close:before {
  content: "\e92f";
}
.icon-arrow-open:before {
  content: "\e930";
}
.icon-bell-active .path1:before {
  content: "\e900";
  color: rgb(61, 67, 77);
}
.icon-bell-active .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(230, 0, 0);
}
.icon-bell-active .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(61, 67, 77);
}
.icon-arrow-down:before {
  content: "\e903";
  color: #cc2a00;
}
.icon-arrow-neutral:before {
  content: "\e904";
  color: #515861;
}
.icon-arrow-up:before {
  content: "\e905";
  color: #55a51c;
}
.icon-boerse-maerkte:before {
  content: "\e90e";
  color: #168cac;
}
.icon-facebook:before {
  content: "\e907";
}
.icon-ive .path1:before {
  content: "\e908";
  color: rgb(131, 180, 40);
}
.icon-ive .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 254);
}
.icon-ive .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(61, 67, 77);
}
.icon-ive .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(61, 67, 77);
}
.icon-ive .path5:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(61, 67, 77);
}
.icon-linkedin:before {
  content: "\e90d";
}
.icon-micro:before {
  content: "\e939";
}
.icon-news:before {
  content: "\e90f";
  color: #f2bb3a;
}
.icon-plus:before {
  content: "\e910";
}
.icon-portfolio:before {
  content: "\e911";
  color: #85b40d;
}
.icon-profile:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-tools:before {
  content: "\e914";
  color: #e1714d;
}
.icon-twitter:before {
  content: "\e915";
}
.icon-xing:before {
  content: "\e916";
}
.icon-logo .path1:before {
  content: "\e917";
  color: rgb(131, 180, 40);
}
.icon-logo .path2:before {
  content: "\e918";
  margin-left: -4.6552734375em;
  color: rgb(255, 255, 254);
}
.icon-logo .path3:before {
  content: "\e919";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path4:before {
  content: "\e91a";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path5:before {
  content: "\e91b";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path6:before {
  content: "\e91c";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path7:before {
  content: "\e91d";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path8:before {
  content: "\e91e";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path9:before {
  content: "\e91f";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path10:before {
  content: "\e920";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path11:before {
  content: "\e921";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-logo .path12:before {
  content: "\e922";
  margin-left: -4.6552734375em;
  color: rgb(61, 67, 77);
}
.icon-ivestor-logo-inverted .path1:before {
  content: "\e923";
  color: rgb(131, 180, 40);
}
.icon-ivestor-logo-inverted .path2:before {
  content: "\e924";
  margin-left: -4.5908203125em;
  color: rgb(255, 255, 254);
}
.icon-ivestor-logo-inverted .path3:before {
  content: "\e925";
  margin-left: -4.5908203125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor-logo-inverted .path4:before {
  content: "\e926";
  margin-left: -4.5908203125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor-logo-inverted .path5:before {
  content: "\e927";
  margin-left: -4.5908203125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor-logo-inverted .path6:before {
  content: "\e928";
  margin-left: -4.5908203125em;
  color: rgb(255, 255, 255);
}
.icon-ivestor-logo-inverted .path7:before {
  content: "\e929";
  margin-left: -4.5908203125em;
  color: rgb(255, 255, 255);
}
.icon-ivestor-logo-inverted .path8:before {
  content: "\e92a";
  margin-left: -4.5908203125em;
  color: rgb(255, 255, 255);
}
.icon-ivestor-logo-inverted .path9:before {
  content: "\e92b";
  margin-left: -4.5908203125em;
  color: rgb(255, 255, 255);
}
.icon-ivestor-logo-inverted .path10:before {
  content: "\e92c";
  margin-left: -4.5908203125em;
  color: rgb(255, 255, 255);
}
.icon-ivestor-logo-inverted .path11:before {
  content: "\e92d";
  margin-left: -4.5908203125em;
  color: rgb(255, 255, 255);
}
.icon-ivestor-logo-inverted .path12:before {
  content: "\e92e";
  margin-left: -4.5908203125em;
  color: rgb(255, 255, 255);
}
.icon-replace:before {
  content: "\e94b";
}
.icon-warning:before {
  content: "\e94e";
  color: #f1bb39;
}
.icon-info:before {
  content: "\e94f";
  color: #148cac;
}
.icon-error:before {
  content: "\e950";
  color: #cc2a00;
}
.icon-confirmation:before {
  content: "\e951";
  color: #84b30e;
}
.icon-spinner:before {
  content: "\e955";
}
.icon-ivestor_Logo_beta_01 .path1:before {
  content: "\e956";
  color: rgb(131, 180, 40);
}
.icon-ivestor_Logo_beta_01 .path2:before {
  content: "\e957";
  margin-left: -6.7705078125em;
  color: rgb(255, 255, 254);
}
.icon-ivestor_Logo_beta_01 .path3:before {
  content: "\e958";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path4:before {
  content: "\e959";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path5:before {
  content: "\e95a";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path6:before {
  content: "\e95b";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path7:before {
  content: "\e95c";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path8:before {
  content: "\e95d";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path9:before {
  content: "\e95e";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path10:before {
  content: "\e95f";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path11:before {
  content: "\e960";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path12:before {
  content: "\e961";
  margin-left: -6.7705078125em;
  color: rgb(61, 67, 77);
}
.icon-ivestor_Logo_beta_01 .path13:before {
  content: "\e962";
  margin-left: -6.7705078125em;
  color: rgb(131, 180, 40);
}
.icon-ivestor_Logo_beta_01 .path14:before {
  content: "\e963";
  margin-left: -6.7705078125em;
  color: rgb(131, 180, 40);
}
.icon-ivestor_Logo_beta_01 .path15:before {
  content: "\e964";
  margin-left: -6.7705078125em;
  color: rgb(131, 180, 40);
}
.icon-ivestor_Logo_beta_01 .path16:before {
  content: "\e965";
  margin-left: -6.7705078125em;
  color: rgb(131, 180, 40);
}
