@import './mediaqueries.scss';

:root {
  --mainFont: 'Droid Sans';
  --mainFontBold: 'Droid Sans Bold';
  --iconFont: 'icomoon';

  --fontSizeTiny: 11px;
  --fontSizeSmall: 12px;
  --fontSizeSmaller: 13px;
  --fontSizeRegular: 14px;
  --fontSize15: 15px;
  --fontSizeMedium: 16px;
  --fontSizeHeader5: 18px;
  --fontSizeMedium2: 21px;
  --fontSizeBig: 24px;
  --fontSize28: 28px;
  --fontSizeHuge: 36px;
  --fontSizeHuge2: 38px;

  --lineHeightBig: 34px;
  --lineHeightHuge: 49px;

  --chartFont: 'Droid Sans';
  --chartFontBold: 'Droid Sans Bold';
  --chartFontSize: 14px;
}

@font-face {
  font-family: var(--iconFont);
  src: url('/assets/fonts/icomoon/fonts/icomoon.ttf');
  src: url('/assets/fonts/icomoon/fonts/icomoon.woff') format('woff'), url('/assets/fonts/icomoon/fonts/icomoon.ttf') format('truetype'), url('/assets/fonts/icomoon/fonts/icomoon.svg') format('svg');
}

@font-face {
  font-family: var(--mainFont);
  src: url('/assets/fonts/droid/DroidSans.ttf');
  src: url('/assets/fonts/droid/DroidSans.woff') format('woff'), url('/assets/fonts/droid/DroidSans.ttf') format('truetype'), url('/assets/fonts/droid/DroidSans.svg') format('svg');
}

@font-face {
  font-family: var(--mainFontBold);
  src: url('/assets/fonts/droid/DroidSans-Bold.ttf');
  src: url('/assets/fonts/droid/DroidSans-Bold.woff') format('woff'), url('/assets/fonts/droid/DroidSans-Bold.ttf') format('truetype'), url('/assets/fonts/droid/DroidSans-Bold.svg') format('svg');
}

strong {
  font-family: var(--mainFontBold);
  font-weight: normal;
}

.body-1,
.body-1 p {
  font-family: var(--mainFont);
  font-size: var(--fontSizeMedium);
  line-height: 24px;
  font-weight: 400;
}

h1,
h2,
h3,
h4 {
  color: var(--textColorRegular);
  font-family: var(--mainFont);
  font-weight: normal;
  font-size: var(--fontSizeHuge);
  line-height: var(--lineHeightHuge);
  padding: 0;
  margin: 0 0 var(--spacesM) 0;
}

h2 {
  color: var(--primaryColor);
  font-family: var(--mainFontBold);
  font-size: var(--fontSizeBig);
  line-height: var(--lineHeightBig);
  margin: 0 0 var(--spacesS) 0;
}

h3,
.header-3 {
  font-family: var(--mainFont);
  color: var(--buttonColdBlack);
  font-size: var(--fontSizeHuge2);
  line-height: 46px;
  padding: 0;
  margin: 0 0 var(--spacesS) 0;
}

h4,
.header-4 {
  font-family: var(--mainFont);
  color: var(--primaryColor);
  font-size: var(--fontSizeBig);
  line-height: 32px;
  padding: 0;
  margin: 0 0 var(--spacesXS) 0;
}

h5,
.header-5 {
  font-family: var(--mainFontBold);
  color: var(--primaryColor);
  font-size: var(--fontSizeHeader5);
  line-height: 26px;
  padding: 0;
  margin: 0 0 var(--spacesXS) 0;
}