@import './scss/colors.scss';
@import './scss/chart.scss';
@import './scss/theme.scss';
@import '../node_modules/normalize.css/normalize.css';
@import './assets/fonts/icomoon/style.css';
@import './scss/fonts.scss';
@import './scss/mediaqueries.scss';
@import './scss/measures.scss';
@import './scss/print.scss';

* {
  box-sizing: border-box;
  outline: none;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

body {
  background: var(--bgColor);
  font-family: var(--mainFont);
  font-size: var(--fontSizeRegular);
  color: var(--textColorRegular);

  &.bg-custom {
    background-color: var(--bgColorWhite);
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

a {
  color: var(--linkColor);
  text-decoration: none;
}

p {
  font-family: var(--mainFont);
  font-size: var(--fontSizeRegular);
  color: var(--textColorRegular);
  line-height: 18px;
  margin: 0 0 12px 0;
}

.text-content {
  p {
    color: var(--secondaryColor);
    font-family: var(--mainFont);
    font-size: var(--fontSizeMedium);
    line-height: 24px;
    margin:0 0 24px 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

progress {
  background-color: var(--bgColorShaded)3;
  border: none;
  color: var(--bgColorShaded)3;
  margin: 0;
  height: 16px;
  -webkit-appearance: none;
  min-width: 100%;
  max-width: 100%;
}

progress::-ms-fill {
  background-color: var(--chartColor04);
}

progress::-moz-progress-bar {
  background: var(--chartColor04);
}

progress[value]::-webkit-progress-bar {
  background-color: var(--bgColorShaded)3;
}

progress[value]::-webkit-progress-value {
  background-color: var(--chartColor04);
  border-right: 1px solid #fff;
  transition: width ease-out 800ms;
}

html,
body {
  height: 100%;
  margin: 0;
}