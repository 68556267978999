@import '../scss/mediaqueries.scss';

@mixin round {
  border-radius: 50%;
  border: 1px solid transparent;
  line-height: 39px;
  height: 40px;
  width: 40px;
  font-size: 16px;
  margin: 0 var(--spacesXS) 0 0;
  transition: background-color ease-in-out 100ms;

  &.medium {
    line-height: 31px;
    height: 32px;
    width: 32px;
  }

  &.icon {
    span {
      position: relative;
      left: 1px;
      top: 1px;
    }
  }
}

@mixin roundEdges {
  border-radius: 20px;
  border: 1px solid transparent;
  line-height: 39px;
  height: 40px;
  padding: 0 20px;
  margin: 0;
  font-size: 16px;
  transition: background-color ease-in-out 100ms;

  &.small {
    border-radius: 12px;
    line-height: 23px;
    height: 24px;
    padding: 0 12px;
    font-size: 12px;
  }

  &.medium {
    border-radius: 16px;
    line-height: 31px;
    height: 32px;
    padding: 0 16px;
    font-size: 14px;
  }

  &.icon {
    span:not(.text) {
      position: relative;
      top: 1px;
    }

    .text {
      margin-left: var(--spacesXS);
    }
  }
}

@mixin colorButtonFilled($property, $default, $hover, $pressed) {
  &.#{$property} {
    background: $default;

    &:hover {
      background:#{$hover};
    }

    &:active {
      background:#{$pressed};
    }
  }
}

@mixin colorButtonOutline($property, $default, $hover, $pressed, $text) {
  &.#{$property} {
    background: transparent;
    border: 1px solid #{$default};
    color: #{$text};

    &:hover {
      background: #fff;
      border-color:#{$hover};
    }

    &:active {
      background: transparent;
      border-color:#{$pressed};
    }
  }
}

@mixin colorReset($property, $default, $hover, $pressed, $text) {
  &.#{$property} {
    &:hover {
      span {
        &::before {
          color:#{$hover};
        }
      }
    }

    &:active {
      span {
        &::before {
          color:#{$pressed};
        }
      }
    }
  }
}



.button,
button {
  appearance: none;
  border: none;
  border-radius: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  font-family: var(--mainFont);
  position: relative;
  user-select: none;

  &.text {
    color: var(--linkColor);
    font-size: var(--fontSizeMedium);
    padding: 0 0 0 20px;
    position: relative;
    display: inline-block;

    &:before {
      content: '\e92f';
      font-family: var(--iconFont);
      transform: rotate(90deg);
      position: absolute;
      font-size: 8px;
      left: 0;
      top: 0px;
    }

    &.filters {
      padding-left: 25px;

      &:before {
        content: '\e940';
        font-family: var(--iconFont);
        transform: rotate(0deg);
        font-size: inherit;
      }
    }
  }

  &.primary {
    color: #fff;
    @include roundEdges;
    @include colorButtonFilled('green', var(--buttonGreen), var(--buttonGreenHover), var(--buttonGreenActive));
    @include colorButtonFilled('cold-black', var(--buttonColdBlack), var(--buttonColdBlackHover), var(--buttonColdBlackActive));
    @include colorButtonOutline('ghost', var(--buttonGhost), var(--buttonGhostHover), var(--buttonGhostActive), var(--buttonGhost));
  }

  &.secondary {
    color: var(--buttonColdBlack);
    @include roundEdges;
    @include colorButtonFilled('cold-black', var(--buttonColdBlack20), var(--buttonColdBlack20Hover), var(--buttonColdBlack20Active));
    @include colorButtonOutline('ghost', var(--buttonGhost), var(--buttonGhostHover), var(--buttonGhostActive), var(--buttonGhost));
  }

  &.round {
    color: #fff;
    @include round;
    @include colorButtonFilled('green', var(--buttonGreen), var(--buttonGreenHover), var(--buttonGreenActive));
    @include colorButtonOutline('ghost', var(--buttonGhost), var(--buttonGhostHover), var(--buttonGhostActive), var(--buttonGhost));
  }

  &.ghost-icon {
    @include round;
    @include colorReset('default', var(--buttonGhost), var(--buttonGhostHover), var(--buttonGhostActive), var(--buttonGhost));
  }

  &.menu {
    color: var(--secondaryColor);
    @include roundEdges;
    @include colorButtonFilled('menu', transparent, var(--buttonColdBlack20Active), transparent);
    span {
      font-size: 20px;
      position: absolute;
      top: 5px;
      left: 6px;
      &:before {
        color: var(--secondaryColor);
      }
    }
  }

  &.snow-man {
    color: var(--secondaryColor);
    @include roundEdges;
    @include colorButtonFilled('snow-man', transparent, var(--buttonColdBlack20Active), transparent);
    span {
      position: absolute;
      top: 7px;
      left: 14px;
    }
  }

  &.reset {
    @include round;
    @include colorReset('default', var(--buttonGhost), var(--buttonGhostHover), var(--buttonGhostActive), var(--buttonGhost));
    top: 9px !important;


    &.medium {
      .icon-reset {
        &:before {
          font-size: 32px;
          position: relative !important;
          top: 0px;
        }
      }
    }

    .icon-reset {
      &:before {
        font-size: 40px;
        position: relative !important;
        top: 2px;
        left: -2px;
      }
    }
  }

  .icon-filter {
    &:before {
      font-size: 11px;
      color: #fff;
      top: -2px;
      position: relative;
    }
  }
}
