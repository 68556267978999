@mixin mediaQueryMobile {
    @media all and (max-width: 599.99px) {
        @content;
    }
}

@mixin mediaQueryMedium {
    @media all and (min-width: 600px) {
        @content;
    }
}

@mixin mediaQueryRegular {
    @media all and (min-width: 961px) {
        @content;
    }
}

@mixin mediaQueryLarge {
    @media all and (min-width: 1290px) {
        @content;
    }
}

@mixin mediaQueryIphoneX {
    @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
        @content;
    }
}