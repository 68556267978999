$ct-class-series: ct-series !default;
$ct-series-names: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y) !default;
$ct-series-colors: (var(--chartColor01),
  var(--chartColor02),
  var(--chartColor03),
  var(--chartColor04),
  var(--chartColor05),
  var(--chartColor06),
  var(--chartColor07),
  var(--chartColor08),
  var(--chartColor09),
  var(--chartColor10),
  var(--chartColor11),
  var(--chartColor12),
  var(--chartColor13),
  var(--chartColor14),
  var(--chartColor15),
  var(--chartColor16),
  var(--chartColor17),
  var(--chartColor18),
  var(--chartColor19),
  var(--chartColor20),
  var(--chartColor21),
  var(--chartColor22),
  var(--chartColor23),
  var(--chartColor24),
  var(--chartColor25));


@import "chartist/dist/scss/chartist.scss";
@import './mediaqueries.scss';
@import './error.scss';

.chart-error {
  @include chartError;
}

.ct-donut-chart {
  .ct-series {
    cursor: pointer;
  }

  .ct-tooltip {
    border-radius: 3px;
    border: 1px solid var(--chartTextColor);
    color: var(--chartTextColor);
    z-index: 99 !important;
    font-size: var(--chartFontSize);
    background: #FFF;
    padding: 7px;
    display: none;
    min-width: 100px;
    user-select: none;
    position: fixed;

    &:before {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 9px solid var(--chartTextColor);
      content: "";
      left: 4px;
      position: absolute;
      bottom: -9px;
    }

    &:after {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 8px solid #fff;
      content: "";
      left: 5px;
      position: absolute;
      bottom: -8px;
    }

    .label {
      display: block;
      font-family: var(--chartFontBold);
      margin: 0 0 5px 0;
    }

    .percents {
      &:before {
        content: ' (';
      }

      &:after {
        content: '%)';
      }
    }
  }

  .#{$ct-class-slice-donut} {
    cursor: pointer;
    transition: ease-in-out 100ms;

    &:hover {
      stroke-width: 30px !important;
    }
  }

}

.ct-bar-chart,
.ct-line-chart {
  font-family: var(--chartFont);

  .ct-chart-bar,
  .ct-chart-line {

    .ct-grids {

      .ct-grid {
        stroke: #F0F2F4;
        stroke-width: 1px;
        stroke-dasharray: 0;
      }

      .ct-targetline {
        stroke: var(--chartTargetLine);
        stroke-width: 1px;

        &.previousClose {
          stroke-dasharray: 2;
        }
      }
    }

    .ct-series {

      .ct-line {
        stroke-width: 2px;

        &.ct-threshold-above {
          stroke: var(--chartTrendUp);
        }

        &.ct-threshold-below {
          stroke: var(--chartTrendDown);
        }
      }

      .ct-ohlc-line,
      .ct-candlestick-line {
        stroke: var(--chartTrendUp);

        &.up {
          stroke: var(--chartTrendUp);
        }

        &.down {
          stroke: var(--chartTrendDown);
        }
      }

      .ct-point {
        display: none;
      }

      .ct-area {
        fill-opacity: 0.1;

        &.ct-threshold-above {
          fill: var(--chartTrendUp);
        }

        &.ct-threshold-below {
          fill: var(--chartTrendDown);
        }
      }

      .ct-bar-dynamic {
        stroke: var(--chartBarColor);

        &.up {
          stroke: var(--chartTrendUp);
        }

        &.neutral {
          stroke: var(--chartTrendNeutral);
        }

        &.down {
          stroke: var(--chartTrendDown);
        }
      }
    }

    .ct-labels {
      .ct-label {
        color: var(--chartLabelColor);
        font-size: 13px;

        &.ct-label-y {
          justify-content: flex-end;
        }
      }
    }
  }

  .ct-chart-bar {
    .ct-labels {
      .ct-label {
        &.ct-label {
          text-anchor: start;
          justify-content: start;
        }

        &.ct-horizontal {
          flex-flow: wrap-reverse;
        }

        &.ct-label-y {
          justify-content: flex-end;
        }
      }
    }
  }

  .ct-pointer {
    position: absolute;
    height: 15px;
    width: 15px;
    margin: -8px 0 0 -8px;
    display: none;

    &:before {
      border-radius: 50%;
      border: 4px solid var(--chartTextColor);
      opacity: 0.3;
      width: 7px;
      height: 7px;
      content: "";
      position: absolute;
    }

    &:after {
      background: var(--chartTextColor);
      border-radius: 50%;
      width: 7px;
      height: 7px;
      content: "";
      left: 4px;
      bottom: 4px;
      position: absolute;
    }
  }

  .ct-tooltip {
    z-index: 99 !important;
    display: none;
    min-width: 100px;
    user-select: none;
    position: absolute;
    margin: -100px 0 0 0;

    &.reverse {
      .content-wrapper {
        margin: 13px 13px 0 0;
      }
    }

    .content-wrapper {
      border: 1px solid var(--chartTextColor);
      color: var(--chartTextColor);
      font-size: 13px;
      background: #FFF;
      padding: 12px 10px;
      margin: 13px 0 0 13px;

      .title {
        font-family: var(--chartFontBold);
        line-height: 18px;
      }

      .label {
        display: inline;
        margin: 0 5px 0 0;
        line-height: 18px;
      }

      .value {
        display: inline;
        font-family: var(--chartFontBold);
        line-height: 18px;
      }
    }
  }

  @for $i from 0 to length($ct-series-names) {
    .ct-series-#{nth($ct-series-names, $i+1)} {
      border-color: nth($ct-series-colors, $i+1) !important;
    }

    .ct-pointer-#{nth($ct-series-names, $i+1)} {
      &:before {
        border-color: nth($ct-series-colors, $i+1) !important;
      }

      &:after {
        background: nth($ct-series-colors, $i+1) !important;
      }
    }
  }

  .ct-date {
    z-index: 99 !important;
    line-height: 18px;
    user-select: none;
    position: absolute;
    bottom: -132px;
    white-space: nowrap;
    border: 1px solid var(--chartLabelColor);
    background-color: #FFFFFF;
    padding: 7px 16px 5px 16px;
    color: var(--chartTextColor);
    font-size: 13px;
    display: inline-block;

    &:before {
      content: '';
      height: 303px;
      width: 1px;
      background: var(--chartLabelColor);
      position: absolute;
      left: 50%;
      bottom: 30px;
    }

    .value {
      font-family: var(--chartFontBold);
    }
  }
}

@include mediaQueryMedium {
  .ct-line-chart {
    .ct-date {
      &:before {
        height: 403px;
      }
    }
  }
}

@include mediaQueryRegular {
  .ct-line-chart {
    .ct-date {
      &:before {
        height: 603px;
      }
    }
  }
}
