@media print {

  h1,
  h2 {
    font-family: var(--mainFont);
    font-size: var(--fontSizeMedium);
    color: var(--primaryColor);
    margin: 0;
    line-height: 22px !important;
  }

  body {
    font-size: var(--fontSizeSmall) !important;
  }

  progress,
  header {
    -webkit-print-color-adjust: exact !important;
  }

  .content,
  .component {
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
  }

  .content-wrapper,
  .content-header {
    margin: 0 !important;
    max-width: none;
    width: 100% !important;
  }

  .text-content {
    p {
      font-size: inherit !important;
      line-height: 18px !important;
    }
  }

  table {
    margin-top: 14px !important;
    thead {
      tr {
        border-bottom: 1px solid var(--tableBorderColor) !important;
        th {
          font-size: var(--fontSizeSmall) !important;
          padding: 0 !important;
          text-align: left !important;
        }
      }
    }
    tbody {
      tr {
        border-top: none !important;
        td {
          font-size: var(--fontSizeSmall) !important;
          padding: 0 !important;
        }
      }
    }
  }

  app-button-more,
  app-breadcrumb,
  app-function-bar,
  app-footer,
  app-tabs,
  app-filters,
  app-tabs-static,
  app-associated-derivatives,
  app-search,
  app-chart-benchmarks,
  app-instrument-news,
  app-times-sales-filters,
  app-filters-date {
    display: none !important;
  }

  app-header {
    display: block !important;
    border-bottom: 1px solid var(--primaryColor);
    padding: 0 0 6px 0;
    margin: 0 0 20px 0 !important;

    header {
      padding: 0 !important;
      height: 48px !important;
      width: 100% !important;
      display: block !important;
      background-position: 0px 0px !important;

      ul {
        display: none !important;
      }
    }
  }

  app-product {

    app-instrument-header {
      display: block;
      margin-left: 0px !important;
      margin-right: 0px !important;

      .identifiers {
        margin: 14px 0 0 0 !important;

        li {
          display: inline;

          &:after {
            content: ','
          }

          &:last-child {
            &:after {
              content: '';
            }
          }

          .value {
            font-family: var(--mainFontBold);
          }
        }
      }

      .tiles {
        margin: 16px 0 0 0 !important;
        border-top: 1px solid var(--tableBorderColor) !important;

        li {
          display: block !important;
          padding: 0 !important;
          width: 100% !important;
          margin: 0 !important;
          border-bottom: 1px solid var(--tableBorderColor) !important;
          background: none !important;
          
          &:after {
            display: none;
          }
          
          .label,
          .timestamp,
          .footer,
          .value {
            text-align: left !important;
            font-family: var(--mainFont) !important;
            font-size: var(--fontSizeSmall) !important;
            width: calc(100% / 4) !important;
            display: inline-block;
            margin: 0 !important;
            &::before {
              font-size: 9px !important
            }
          }
          .label {
            font-family: var(--mainFontBold) !important;
          }

        }
      }
    }

    .component {
      margin: var(--spacesL) 0 0 0 !important;
    }

    app-overview-chart {
      .chart-wrapper {
        .chart {
          height:350px !important;
          svg {
            height:350px !important;
          }
          .ct-tooltip,
          .ct-date,
          .ct-pointer {
            display:none !important;
          }
        }
      } 
      
    }
  }

  app-news-detail {
    h1 {
      margin: 0 0 8px 0 !important;
    }

    .source,
    .category {
      background: none !important;
      color: inherit !important;
      font-size: inherit !important;
    }

    img {
      display: none;
    }

    .wrapper {
      margin: var(--spacesS) 0 0 0 !important;
    }
  }

  app-company {
    table {
      thead {
        tr {
          th {
            text-align:right !important;
            span {
              background: none !important;
              padding:0 !important;
              color: inherit !important;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            text-align: right !important;
            &:first-child {
              text-align: left !important;
            }
          }
        }
      }
    }
  }

  app-times-sales {
    progress {
      display: none !important;
    }
  }
}
